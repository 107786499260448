import React from "react";

export const InstaIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00004 0.333008C3.77763 0.333008 0.333374 3.77727 0.333374 7.99967V17.9997C0.333374 22.2215 3.77751 25.6663 8.00004 25.6663H18C22.222 25.6663 25.6667 22.2216 25.6667 17.9997V7.99967C25.6667 3.77715 22.2219 0.333008 18 0.333008H8.00004ZM8.00004 2.33301H18C21.1409 2.33301 23.6667 4.8582 23.6667 7.99967V17.9997C23.6667 21.1404 21.1407 23.6663 18 23.6663H8.00004C4.85857 23.6663 2.33337 21.1405 2.33337 17.9997V7.99967C2.33337 4.85808 4.85845 2.33301 8.00004 2.33301ZM19.6667 4.99967C18.93 4.99967 18.3334 5.59634 18.3334 6.33301C18.3334 7.06967 18.93 7.66634 19.6667 7.66634C20.4034 7.66634 21 7.06967 21 6.33301C21 5.59634 20.4034 4.99967 19.6667 4.99967ZM13 6.33301C9.33016 6.33301 6.33337 9.32979 6.33337 12.9997C6.33337 16.6696 9.33016 19.6663 13 19.6663C16.6699 19.6663 19.6667 16.6696 19.6667 12.9997C19.6667 9.32979 16.6699 6.33301 13 6.33301ZM13 8.33301C15.5888 8.33301 17.6667 10.4109 17.6667 12.9997C17.6667 15.5885 15.5888 17.6663 13 17.6663C10.4113 17.6663 8.33337 15.5885 8.33337 12.9997C8.33337 10.4109 10.4113 8.33301 13 8.33301Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.13386"
        y1="18.2359"
        x2="27.1957"
        y2="11.8928"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
