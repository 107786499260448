import React from "react";

export const LeafVector = ({ className = "", style = {} }) => (
  <svg
    style={style}
    className={className}
    width="286"
    height="206"
    viewBox="0 0 286 206"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 205L0.834997 204.942L1.15051 205.089L1 205ZM131.276 150.239L131.259 150.065L131.276 150.239ZM285 1.00014L285.168 1.05039L285.345 0.459776L284.884 0.869324L285 1.00014ZM156.597 52.8079L156.571 52.6349L156.597 52.8079ZM1.15051 205.089C11.7055 187.296 47.4226 158.755 131.294 150.413L131.259 150.065C47.3357 158.412 11.4857 186.98 0.849489 204.911L1.15051 205.089ZM131.294 150.413C248.391 138.766 275.904 31.9546 285.168 1.05039L284.832 0.949894C275.559 31.8874 248.098 138.444 131.259 150.065L131.294 150.413ZM284.884 0.869324C271.175 13.0504 236.282 40.6136 156.571 52.6349L156.623 52.981C236.408 40.9485 271.362 13.3523 285.116 1.13096L284.884 0.869324ZM156.571 52.6349C91.031 62.5189 58.2255 83.1919 38.7397 110.126C29.0006 123.587 22.5958 138.604 17.0904 154.595C14.3378 162.591 11.809 170.833 9.20134 179.247C6.59333 187.662 3.90604 196.25 0.834997 204.942L1.165 205.058C4.23857 196.36 6.92766 187.766 9.53566 179.351C12.144 170.935 14.6709 162.699 17.4213 154.709C22.9222 138.731 29.3135 123.752 39.0233 110.331C58.4354 83.4989 91.1391 62.8566 156.623 52.981L156.571 52.6349Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="66.0265"
        y1="145.165"
        x2="285.055"
        y2="53.3332"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
