export const currencySymbols = {
  rupee: '₹',
  dollar: '$',
  euro: '€',
  pound: '£'
};

export const mainNavigation = [
  { name: 'Discover R&G', link: '/#discover' },
  { name: 'Skin Routine', link: '/#skin-routine' },
  { name: 'R&G Essentials', link: '/#essentials' },
  { name: 'Vasu Healthcare', link: '/#vasu-healthcare' }
];

// -----------------------
//
//
// Home Skin Types
//
//
// -----------------------

export const skinType = [
  {
    name: 'Dry Skin',
    type: 'dry',
    description:
      'Some of the common characteristics of dry skin include tightness, roughness, flaking, and aging. With R&G, mild and skin-friendly range, and the suggested AM/ PM regime followed every day, can get it all pumped up, into a brighter nourished skin.',
    washDrops: {
      morning: 1,
      night: 1
    },
    serumDrops: {
      morning: 0,
      night: 1
    },
    creamDrops: {
      morning: 2,
      night: 0
    },
    mainImage: '/images/skin-type/dry-1.jpg',
    thumbImage: '/images/skin-type/dry-2.jpg'
  },
  {
    name: 'Sensitive Skin',
    type: 'sensitive',
    description:
      'Sensitive skin is less of a skin type and more like a skin condition caused by various factors like skin disorders, allergies, or contact to irritants present in certain products. Our Mild range made for every skin type can help solve this condition',
    washDrops: {
      morning: 0,
      night: 1
    },
    serumDrops: {
      morning: 0,
      night: 1
    },
    creamDrops: {
      morning: 1,
      night: 0
    },
    mainImage: '/images/skin-type/sensitive-1.jpg',
    thumbImage: '/images/skin-type/sensitive-2.jpg'
  },
  {
    name: 'Normal Skin',
    type: 'normal',
    description:
      'The T-zone (forehead, chin, and nose) may be a bit oily, but overall sebum and moisture are balanced and the skin is neither too oily nor too dry. With R&G, mild and skin-friendly range, and the suggested AM/ PM regime, followed every day, can get it all pumped up, into a brighter nourished skin.',
    washDrops: {
      morning: 1,
      night: 1
    },
    serumDrops: {
      morning: 0,
      night: 1
    },
    creamDrops: {
      morning: 2,
      night: 0
    },
    mainImage: '/images/skin-type/normal-1.jpg',
    thumbImage: '/images/skin-type/normal-2.jpg'
  },
  {
    name: 'Combination Skin',
    type: 'combination',
    description:
      'Combination skin type is characterized by dry, flaking skin on the cheeks causing various factors like skin disorders, dry cheeks, large pores, and dark spots. Our Mild range made for every skin type can help solve this condition and make it soft and smooth.',
    washDrops: {
      morning: 1,
      night: 1
    },
    serumDrops: {
      morning: 0,
      night: 1
    },
    creamDrops: {
      morning: 1,
      night: 0
    },
    mainImage: '/images/skin-type/combination-1.jpg',
    thumbImage: '/images/skin-type/combination-2.jpg'
  },
  {
    name: 'Oily Skin',
    type: 'oily',
    description:
      'Oily skin is Shiny and often has breakouts some of the common characteristics of Oily skin include enlarged pores, other blemishes. With R&G, mild and skin-friendly range, and the suggested AM/ PM regime, followed every day, can get it all pumped up, into a brighter nourished skin.',
    washDrops: {
      morning: 1,
      night: 1
    },
    serumDrops: {
      morning: 0,
      night: 1
    },
    creamDrops: {
      morning: 1,
      night: 0
    },
    mainImage: '/images/skin-type/oily-1.jpg',
    thumbImage: '/images/skin-type/oily-2.jpg'
  }
];

// -----------------------
//
//
// Home Products Data
//
//
// -----------------------

export const productList = [
  // Serum
  {
    id: 'serum',
    name: 'R&G Face Serum with Vitamin-C ',
    readLink: '/serum',
    link: 'https://www.vasustore.com/r&g-face-serum-with-vitamin-c-for-skin-brightening',
    tag: 'Skin Brightening',
    img: '/images/products/960x960/serum.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/serum/01.png' },
      { id: 1, value: '/images/products/1080x1080/serum/02.png' },
      { id: 2, value: '/images/products/1080x1080/serum/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/serum/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/serum/05.jpg' },
      { id: 5, value: '/images/products/1080x1080/serum/06.jpg' }
    ],
    size: '25ml/ 0.84 FL.OZ',
    price: 525,
    mrp: 699,
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          'Expertly formulated with the most stable derivative of <b>Vitamin-C</b>, this fast-absorbing face serum is fortified with <b>Niacinamide, Ferulic acid</b> and <b>Natural Moisturizing Factors,</b> which work synergistically to help boost collagen synthesis, thereby reducing fine lines & wrinkles. It fades dark spots, evens skin tone and helps promote visibly radiant, smoother and healthier- looking skin.'
      },
      {
        name: 'ingredients',
        value:
          '<p><b>3-O-Ethyl ascorbic acid</b> - Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><b>Niacinamide</b> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><b>Natural Moisturizing Factors</b> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><b>Ferulic acid</b> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value: 'Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required.'
      }
    ]
  },
  // Cream
  {
    id: 'cream',
    name: 'R&G Face Cream',
    readLink: '/cream',
    link: 'https://www.vasustore.com/r&g-face-cream-for-skin-brightening-with-spf-15',
    tag: 'Skin Brightening with SPF-15',
    img: '/images/products/960x960/cream.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/cream/01.png' },
      { id: 1, value: '/images/products/1080x1080/cream/02.png' },
      { id: 2, value: '/images/products/1080x1080/cream/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/cream/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/cream/05.jpg' },
      { id: 5, value: '/images/products/1080x1080/cream/06.jpg' }
    ],
    size: '65ml/ 1.69 FL.OZ',
    price: 375,
    mrp: 499,
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          'A rich combination of <b>clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</b> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.'
      },
      {
        name: 'ingredients',
        value:
          '<p><b>Blend of Brassica Campestris Seed Oil (and) Glycyrrhiza Glabra Root Extract (and) Althaea Officinalis Root Extract (and) Oryza Sativa Bran Extract</b> – Natural Brightening, Age Revitalizing and Anti-dark spot action</p>' +
          '<p><b>Vitamin-E</b> - An anti-oxidant that lightens scars, while nourishing and protecting skin from free radicals</p>' +
          '<p><b>Glycolic acid</b> - Natural exfoliant to remove dead skin cells and stimulate growth of new cells. This way it keeps the pores of the skin unclogged and prevents acne</p>' +
          '<p><b>Niacinamide</b> – Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><b>Ethylhexyl Methoxycinnamate (and) Octocrylene (and) Ethylhexyl Salicylate (and) Butyl Methoxydibenzoylmethane (and) Benzophenone-3</b> – It acts as an UV-B sunscreen agent. It protects skin from harmful UV radiation</p>'
      },
      {
        name: 'usage',
        value: 'Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required.'
      }
    ]
  },
  // Facewash
  {
    id: 'facewash',
    name: 'R&G Face Wash',
    readLink: '/facewash',
    link: 'https://www.vasustore.com/rose-and-gold-face-wash-cleanses-exfoliates-brightens',
    tag: 'Cleanses – Exfoliates – Brightens',
    img: '/images/products/960x960/facewash.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/face-wash/01.png' },
      { id: 1, value: '/images/products/1080x1080/face-wash/02.jpg' },
      { id: 2, value: '/images/products/1080x1080/face-wash/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/face-wash/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/face-wash/05.jpg' }
    ],
    size: '75ml/ 2.54 FL.OZ',
    price: 187,
    mrp: 249,
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          'Regular exposure to pollution and stress makes our skin look dull and darker. <b>R&G Face wash</b> is fortified with potent ingredients like <b>Licorice, Cucumber, Niacinamide, ProVitamin B5</b> and <b>Glycolic acid,</b> to cleanse and remove dead skin cells, help reduce pigmentation and dullness to uncover a healthy, younger and brighter skin.'
      },
      {
        name: 'ingredients',
        value:
          '<p><b>Licorice extract</b> – Its benefits for skin include astringent properties, antioxidant protection, as well as the ability to promote skin-radiance</b>' +
          '<p><b>Cucumber extract</b> – Cooling, Refreshing</b></p>' +
          '<p><b>Niacinamide</b> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><b>ProVitamin B5 (D- Panthenol)</b> - Acts as a moisturizer, it is soothing to skin and helps build a barrier against water loss</p>' +
          '<p><b>Glycolic acid</b> - Natural exfoliant to remove dead skin cells and stimulate growth of new cells. This way it keeps the pores of the skin unclogged and prevents acne</p>'
      },
      {
        name: 'usage',
        value:
          'Gently massage on wet face to work-up a rich lather with water, avoiding eyes, and rinse off. Use atleast twice daily for effective results'
      }
    ]
  }
];

// -----------------------
//
//
// Beauty Slider Home bottom
//
//
// -----------------------

export const beatuySlider = [
  '/images/beauty/image-1.png',
  '/images/beauty/image-2.png',
  '/images/beauty/image-3.png',
  '/images/beauty/image-4.png',
  '/images/beauty/image-5.png',
  '/images/beauty/image-6.png'
];

// -----------------------
//
//
// Detailed Data of product
//
//
// -----------------------

export const productData = [
  // Serum
  {
    id: 'serum',
    name: 'Serum',
    link: 'https://www.vasustore.com/r&g-face-serum-with-vitamin-c-for-skin-brightening',
    tag: '',
    size: '(25ml/ 0.84 FL.OZ)',
    price: 525,
    mrp: 699,
    smallDesc:
      'Expertly formulated with the most stable derivative of Vitamin-C, this fast-absorbing face serum is fortified with Niacinamide, Ferulic acid and Natural Moisturizing',
    img: '/images/serum.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/serum/01.png' },
      { id: 1, value: '/images/products/1080x1080/serum/02.png' },
      { id: 2, value: '/images/products/1080x1080/serum/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/serum/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/serum/05.jpg' },
      { id: 5, value: '/images/products/1080x1080/serum/06.jpg' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>Expertly formulated with the most stable derivative of <span class="text-customColors-300">Vitamin-C,</span> this fast-absorbing face serum is fortified with <span class="text-customColors-300">Niacinamide, Ferulic acid</span> and <span class="text-customColors-300">Natural Moisturizing Factors,</span> which work synergistically to help boost collagen synthesis, thereby reducing fine lines & wrinkles. It fades dark spots, evens skin tone and helps promote visibly radiant, smoother and healthier- looking skin.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Brighten sun-damaged and dull-looking skin</li>' +
          '<li>Prevent dark spots</li>' +
          '<li>To moisturize and even skin tone</li>' +
          '<li>To act as an anti-oxidant against environmental damage</li>' +
          '<li>Prevent premature skin ageing</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">3-O-Ethyl ascorbic acid</span>- Helps in Skin Lightening and reduces skin darkening after UV exposure. It brightens and tones sun-damaged and dull-looking skin with dark spots. It boosts collagen synthesis and acts as an antioxidant.</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Natural Moisturizing Factors</span> - A proprietary blend of Sodium PCA, Provitamin B5, Sodium Hyaluronate, Proline, Hydroxyproline & Glycerin, having superior moisture retention property</p>' +
          '<p><span class="text-customColors-300">Ferulic acid</span> - It neutralizes free radicals and complements the antioxidant benefits of Vitamin-C for visible anti-aging benefits. It increases firmness and replenishes lipids to reduce wrinkles.</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed. Use daily as required</p>' +
          '<p>Avoid sun exposure after application</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      {
        icon: '/images/products/icons/natural-moisturizing.png',
        value: 'Fortified with Natural Moisturizing Factors (NMF) for superior moisturizing effect'
      },
      {
        icon: '/images/products/icons/quick-absorbing.png',
        value: 'Quick absorbing and fast-acting'
      },
      {
        icon: '/images/products/icons/vitamin-c.png',
        value: 'Formulated with the most stable derivative of Vitamin-C'
      },
      {
        icon: '/images/products/icons/non-greasy.png',
        value: 'Light & Non - Greasy'
      },
      {
        icon: '/images/products/icons/halal.png',
        value: 'HALAL-certified'
      },
      {
        icon: '/images/products/icons/no-harm-animal.png',
        value: 'Animal Cruelty-free'
      }
    ],
    verifiedUser: 5,
    rating: 5,
    reviews: [
      {
        name: 'Ritika Varma',
        isVerified: true,
        title: 'True to claims',
        reviewText:
          '<p>This is the best vitamin C serum and you can see the result from first use itself. Gradual use surely helps in reducing the blemishes. I have included it in my daily skin care routine. You can apply it after toner followed by a good moisturizer for maximum effect.</p>',
        userRating: 5,
        date: '08/11/2021'
      },
      {
        name: 'Dhara Patel',
        isVerified: true,
        title: 'Happy to purchase and will keep buying this product',
        reviewText:
          '<p>Awesome and amazing serum. Tried somany expensive serums from most of other brands but never felt happy or any change in my skin. This serum sinks into skin very fast. Smooth feeling of skin we can feel. My skin got soo better and fair.</p>',
        userRating: 5,
        date: '01/11/2021'
      },
      {
        name: 'Vigna Rathod',
        isVerified: true,
        title: 'Satisfied',
        reviewText:
          '<p>This is my 3rd purchase .it makes skin supple and soft with lightening of skin tone..like any other product you have to be patient and realistic.it is not a magic potion that would work overnight.regular use will definitely make a noticeable change.it is now mandatory part of my skincare regime</p>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Krutika Patel',
        isVerified: true,
        title: 'Skin Care',
        reviewText:
          '<p>Reasons I loved The Product:</p>' +
          '<ul class="list-outside ml-5 list-disc">' +
          '<li>Its a 25ml bottle</li>' +
          '<li>Travel friendly</li>' +
          '<li>You can feel the difference right from its first application and give it little time to see visible results.</li>' +
          '<li>The product is free from parabens, minerals and non comedogenic.</li>' +
          '<li>Helps in addressing acne</li>' +
          '<li>Suitable For All Skin Types</li>' +
          '</ul>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Simran Lamba',
        isVerified: true,
        title: 'Sharing my experience',
        reviewText:
          '<p>I was searching for a good vitamin C serum and I found this in vasustore with good reviews so I decided to give it a try.</p>' +
          "<p>A few days back I've added this beautiful product in my skin care routine and I'm completely in love with R&G VITAMIN C FACE SERUM. I've combination skin and it suits me perfectly.</p>" +
          '<p>So, here I am sharing my experience with you🌝</p>' +
          '<ul class="list-outside">' +
          '<li>This product is extremely light weight and gets absorb into my skin so easily.</li>' +
          "<li>It doesn't feel sticky or heavy like some other serum.</li>" +
          '<li>After applying this on my face I can see a healthy glow on my face.</li>' +
          "<li>I use this before applying my moisturizer and it works just fine on my combination & sensitive skin. I haven't seen any breakouts after using this.</li>" +
          '<li>It is pretty effective and does its job.</li>' +
          '<li>I highly highly recommend this serum. 😇</li>' +
          '</ul>',
        userRating: 5,
        date: '24/10/2021'
      }
    ]
  },
  // Cream
  {
    id: 'cream',
    name: 'Cream',
    link: 'https://www.vasustore.com/r&g-face-cream-for-skin-brightening-with-spf-15',
    tag: '',
    size: '(50ml/ 1.69 FL.OZ)',
    price: 375,
    mrp: 499,
    smallDesc: '',
    img: '/images/cream.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/cream/01.png' },
      { id: 1, value: '/images/products/1080x1080/cream/02.png' },
      { id: 2, value: '/images/products/1080x1080/cream/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/cream/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/cream/05.jpg' },
      { id: 5, value: '/images/products/1080x1080/cream/06.jpg' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>A rich combination of <span class="text-customColors-300">clinically-proven Plant actives, Vitamins, Alpha-Hydroxy acid (AHA) & UV filters,</span> which helps reduce hyper-pigmentation & dark spots and evens out skin tone while protecting from harmful UV radiation, for a visibly brighter and youthful appearance.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Prevent pigmentation</li>' +
          '<li>Protect skin from harmful UV radiation</li>' +
          '<li>To make skin radiant & healthy</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">Blend of Brassica Campestris Seed Oil (and) Glycyrrhiza Glabra Root Extract (and) Althaea Officinalis Root Extract (and) Oryza Sativa Bran Extract </span> - Natural Brightening, Age Revitalizing and Anti-dark spot action</p>' +
          '<p><span class="text-customColors-300">Vitamin-E </span> - An anti-oxidant that lightens scars, while nourishing and protecting skin from free radicals</p>' +
          '<p><span class="text-customColors-300">Glycolic acid</span> - Natural exfoliant to remove dead skin cells and stimulate growth of new cells. This way it keeps the pores of the skin unclogged and prevents acne</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">Ethylhexyl Methoxycinnamate (and) Octocrylene (and) Ethylhexyl Salicylate (and) Butyl Methoxydibenzoylmethane (and) Benzophenone-3</span> - It acts as an UV-B sunscreen agent. It protects skin from harmful UV radiation</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Apply evenly all over face, avoiding eyes, till absorbed.</p>' +
          '<p>Use daily as required.</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      {
        icon: '/images/products/icons/natural-moisturizing.png',
        value: 'Contains Clinically-proven Actives for Skin Brightening'
      },
      {
        icon: '/images/products/icons/quick-absorbing.png',
        value: 'Quick absorbing'
      },
      {
        icon: '',
        value: ''
      },
      {
        icon: '/images/products/icons/non-greasy.png',
        value: 'Non-sticky & Non-greasy'
      },
      {
        icon: '/images/products/icons/halal.png',
        value: 'HALAL-certified'
      },
      {
        icon: '/images/products/icons/no-harm-animal.png',
        value: 'Not tested on animals'
      }
    ],
    verifiedUser: 5,
    rating: 5,
    reviews: [
      {
        name: 'Tulsi Panchasara',
        isVerified: true,
        title: 'One of the best products for sensitive skin.',
        reviewText:
          '<p>am writing this review after using the cream for almost a month. Living in a metro city like Mumbai and being constantly outside in the sun, my skin becomes quite damaged due to travel and shoot time. This Anti-Pollution Mattifying Cream with SPF 15 is the best remedy for sun damage, dirt, and pollutants while giving you a natural matte look. This cream helps balance the natural oils of your skin and gives you the perfect matte finish, leaving you fresh all day long. Must try!!</p>',
        userRating: 5,
        date: '08/11/2021'
      },
      {
        name: 'Maanal Padhiar',
        isVerified: true,
        title: 'Evens out skin tone',
        reviewText:
          '<p>This cream has a really good texture and it can be used daily! It is fading the pimple scars that I have which is exactly what I wanted! Love it!!!!</p>',
        userRating: 5,
        date: '01/11/2021'
      },
      {
        name: 'Divya Khanani',
        isVerified: true,
        title: 'Value for money, happy with the result.',
        reviewText:
          '<p>I use it to prevent damage caused by pollution while nourishing my skin gently. I have seen the results visibly with this product and I use it before work in the morning.</p>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Poonam Tomar',
        isVerified: true,
        title: 'Nice Product',
        reviewText: '<p>Nice product with genuine ingredients.</p>' + '<p>Liked it👍</p>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Sweta Rohit',
        isVerified: true,
        title: 'Great day cream for oily skin',
        reviewText:
          '<p>I love the matte, light-weight texture of this product and the fact that it contains SPF15. It helps keep the oiliness at bay and helps to brighten the skin too. Will highly recommend it.</p>',
        userRating: 5,
        date: '24/10/2021'
      }
    ]
  },
  // Face wash
  {
    id: 'facewash',
    name: 'Facewash',
    link: 'https://www.vasustore.com/rose-and-gold-face-wash-cleanses-exfoliates-brightens',
    tag: '',
    size: '(75ml/ 2.54 FL.OZ)',
    price: 187,
    mrp: 249,
    smallDesc: 'Regular exposure to pollution and stress makes our skin look dull and darker.',
    img: '/images/facewash.jpg',
    media: [
      { id: 0, value: '/images/products/1080x1080/face-wash/01.png' },
      { id: 1, value: '/images/products/1080x1080/face-wash/02.jpg' },
      { id: 2, value: '/images/products/1080x1080/face-wash/03.jpg' },
      { id: 3, value: '/images/products/1080x1080/face-wash/04.jpg' },
      { id: 4, value: '/images/products/1080x1080/face-wash/05.jpg' }
    ],
    attributes: [
      { icon: 'skin', value: 'For All Skin Type' },
      { icon: 'external', value: 'For External Use' },
      { icon: 'away', value: 'Keep away from sunlight & heat' },
      { icon: 'vary', value: 'Results may vary amongst individuals' }
    ],
    tabs: [
      {
        name: 'about',
        value:
          '<p>Regular exposure to pollution and stress makes our skin look dull and darker. <span class="text-customColors-300">R&G Face wash</span> is fortified with potent ingredients like <span class="text-customColors-300">Licorice, Cucumber, Niacinamide, ProVitamin B5</span> and <span class="text-customColors-300">Glycolic acid,</span> to cleanse and remove dead skin cells, help reduce pigmentation and dullness to uncover a healthy, younger and brighter skin.</p>' +
          '<p class="text-customColors-300">SALIENT FEATURES</p>' +
          '<div><p>With regular application, it helps:</p>' +
          '<ul class="ml-5 list-disc">' +
          '<li>Remove dirt and pollution from the skin pores</li>' +
          '<li>Exfoliate to remove dead skin cells</li>' +
          '<li>To make skin appear soft and smooth</li>' +
          '<li>To give a brighter and clearer skin</li>' +
          '</ul></div>'
      },
      {
        name: 'ingredients',
        value:
          '<p><span class="text-customColors-300">Licorice extract</span> - Its benefits for skin include astringent properties, antioxidant protection, as well as the ability to promote skin-radiance</p>' +
          '<p><span class="text-customColors-300">Cucumber extract</span> - Cooling, Refreshing</p>' +
          '<p><span class="text-customColors-300">Niacinamide</span> - Reduces hyperpigmentation, prevents wrinkles & acne, as well as enhances the appearance of dry or damaged skin by reducing flaking and restoring suppleness</p>' +
          '<p><span class="text-customColors-300">ProVitamin B5 (D- Panthenol)</span> - Acts as a moisturizer, it is soothing to skin and helps build a barrier against water loss</p>' +
          '<p><span class="text-customColors-300">Glycolic acid</span> - Natural exfoliant to remove dead skin cells and stimulate growth of new cells. This way it keeps the pores of the skin unclogged and prevents acne</p>'
      },
      {
        name: 'usage',
        value:
          '<p>Gently massage on wet face to work-up a rich lather with water, avoiding eyes, and rinse off.</p>' +
          '<p>Use atleast twice daily for effective results</p>' +
          '<p>Skin care as per your skin type is listed <a href="#skin-routine" class="text-customColors-300">here</a></p>'
      }
    ],
    inside: [
      {
        icon: '/images/products/icons/natural-moisturizing.png',
        value: 'Cleanses gently yet effectively'
      },
      {
        icon: '/images/products/icons/quick-absorbing.png',
        value: 'Has superior conditioning effect'
      },
      {
        icon: '/images/products/icons/vitamin-c.png',
        value: 'Does not over-dry skin'
      },
      {
        icon: '/images/products/icons/non-greasy.png',
        value: 'Free from Animal derivatives'
      },
      {
        icon: '/images/products/icons/halal.png',
        value: 'HALAL-certified'
      },
      {
        icon: '/images/products/icons/no-harm-animal.png',
        value: 'Not tested on animals'
      }
    ],
    verifiedUser: 5,
    rating: 5,
    reviews: [
      {
        name: 'Priya Singh',
        isVerified: true,
        title: 'Dark spots faded with the use of this product',
        reviewText:
          '<p>I have been using R&G FACE WASH since a month. Bought face cream, serum, face wash also.. One good thing about R&G Face Wash is that it controls pimples and all my dark spots started disapperaing after using this face wash.</p>' +
          '<p> </p>' +
          '<p>I m going to buy again ...</p>',
        userRating: 5,
        date: '08/11/2021'
      },
      {
        name: 'Ankita Mungee',
        isVerified: true,
        title: 'Excellent for face wash',
        reviewText:
          '<p>This is very good face wash, it brighten my skin tone and reduce pimples. I like this product.</p>',
        userRating: 5,
        date: '01/11/2021'
      },
      {
        name: 'Anuja Chordiya',
        isVerified: true,
        title: 'Good for skin',
        reviewText:
          '<p>It cleanses + exfoliates both. Loved the product as a cleanser but honestly no result in brightening obviously.</p>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Priya Hemane',
        isVerified: true,
        title: 'Value for money',
        reviewText: '<p>Very good product. It really work on acne prone skin. Thanks R&G</p>',
        userRating: 5,
        date: '25/10/2021'
      },
      {
        name: 'Bhumi Patel',
        isVerified: true,
        title: 'Lightening whitening and brightening you dull dry and hyperpigmented skin',
        reviewText:
          '<p>Believe me or not it will really lightening whitening and brightening your dull dry hyperpigmented skin tone and will Nourish your skin from within. It deeply cleanses and even out skin tone for persistent use, you will feel the difference within the usage of a week. It makes your skin instantly bright and tightens your skin it reduces fine lines. I am greatly satisfied with this product with its overall performance.</p>',
        userRating: 5,
        date: '24/10/2021'
      }
    ]
  }
];

export const payMethods = [
  {
    name: 'master card',
    icon: '/images/pay-icons/mc-icon.png'
  },
  {
    name: 'american express',
    icon: '/images/pay-icons/ae-icon.png'
  },
  {
    name: 'ruPay',
    icon: '/images/pay-icons/rp-icon.png'
  },
  {
    name: 'visa',
    icon: '/images/pay-icons/vc-icon.png'
  },
  {
    name: 'paypal',
    icon: '/images/pay-icons/pp-icon.png'
  },
  {
    name: 'case on delivery',
    icon: '/images/pay-icons/cod-icon.png'
  },
  {
    name: 'net banking',
    icon: '/images/pay-icons/nb-icon.png'
  }
];

export const influeSliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 5,
  variableWidth: false,
  draggable: false,
  responsive: [
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        variableWidth: false,
        centerMode: true,
        centerPadding: '25px'
      }
    }
  ]
};

export const instaArray = [
  {
    id: 0,
    type: 'image',
    link: 'https://www.instagram.com/p/CZjfMyfIJ4Y/',
    url: '/images/instagram/273207930_916036385750220_4259488319974814013_n.webp'
  },
  {
    id: 1,
    type: 'image',
    link: 'https://www.instagram.com/p/CcsO_kFpyhh/',
    url: '/images/instagram/278948539_1000863647470338_6008858094745405559_n.webp'
  },
  {
    id: 2,
    type: 'image',
    link: 'https://www.instagram.com/p/CckmLBKoRUG/',
    url: '/images/instagram/278726428_559053565485652_8689161137410550550_n.webp'
  },
  {
    id: 3,
    type: 'image',
    link: 'https://www.instagram.com/p/Ccfadq1ozbh/',
    url: '/images/instagram/278832464_3535744723326978_706764980274832116_n.webp'
  },
  {
    id: 4,
    type: 'image',
    link: 'https://www.instagram.com/p/CbpZSd2odVS/',
    url: '/images/instagram/277567349_336926078468824_3481313795389775502_n.webp'
  },
  {
    id: 5,
    type: 'image',
    link: 'https://www.instagram.com/p/CcSkgrNIXPm/',
    url: '/images/instagram/278293165_1672549509776057_6343545413104113664_n.webp'
  },
  {
    id: 6,
    type: 'image',
    link: 'https://www.instagram.com/p/Ca60e3QIblR/',
    url: '/images/instagram/275618672_664829858092680_7226369958785036877_n.webp'
  },
  {
    id: 7,
    type: 'image',
    link: 'https://www.instagram.com/p/CbKeb_uICvj/',
    url: '/images/instagram/275808729_3006524466326708_5052432599335265137_n.webp'
  },
  {
    id: 8,
    type: 'image',
    link: 'https://www.instagram.com/p/CahR1JiIBqp/',
    url: '/images/instagram/274944402_3016432551954997_4695130575382338106_n.webp'
  },
  {
    id: 9,
    type: 'image',
    link: 'https://www.instagram.com/p/CaHhDF6oStE/',
    url: '/images/instagram/274085856_911701336049629_7446966435808654249_n.webp'
  },
  {
    id: 10,
    type: 'image',
    link: 'https://www.instagram.com/p/CaUZDf8IEG9/',
    url: '/images/instagram/274510049_298786632345826_1685007649143363810_n.webp'
  }
];

export const InfluencersArray = [
  {
    id: 0,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYQasLAl-79',
    url: '/videos/influencers/10000000_207411714846986_7043863526668888651_n.mp4'
  },
  {
    id: 1,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYQjRzhL1KB',
    url: '/videos/influencers/271240915_1557451777955369_2602979272828774289_n.mp4'
  },
  {
    id: 2,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYTa0XHIXGy',
    url: '/videos/influencers/271277660_663578008387511_5071196572125169120_n.mp4'
  },
  {
    id: 3,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYTa0XHIXGy',
    url: '/videos/influencers/10000000_453272086434148_4733846263368643237_n.mp4'
  },
  {
    id: 4,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYYTTTKIUJU/',
    url: '/videos/influencers/271346406_1070699860170111_6569609886596919725_n.mp4'
  },
  {
    id: 5,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYYyP-OA821/',
    url: '/videos/influencers/271398536_350737089777004_3455241264312012157_n.mp4'
  },
  {
    id: 6,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYVkNDkBDNn/',
    url: '/videos/influencers/10000000_925549108331703_405065515312011846_n.mp4'
  },
  {
    id: 7,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYV055-h4PD/',
    url: '/videos/influencers/10000000_519366735885814_2810729742971928577_n.mp4'
  },
  {
    id: 8,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYZQC6Tlgi7/',
    url: '/videos/influencers/271288461_2890140907912348_6397788388680608957_n.mp4'
  },
  {
    id: 9,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYbpatoJNUt/',
    url: '/videos/influencers/271145888_412922947282817_2316687279768985875_n.mp4'
  },
  {
    id: 10,
    type: 'video',
    link: 'https://www.instagram.com/reel/CYbpoV-leB2/',
    url: '/videos/influencers/271206885_1508745769518632_7236789966670386826_n.mp4'
  }
];
