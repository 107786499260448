import { useState } from '@hookstate/core';
import React from 'react';
import { SubmitArrow } from '../../../static/svg/SubmitArrow';

const ContactWithUs = () => {
  const isFocus = useState(false);
  return (
    <div className="w-full flex flex-col items-start justify-start">
      <p className="font-breadley-sans xs:text-3xl lg:text-5xl text-customColors-300 -mt-3 mb-6">
        Connect with us
      </p>
      <div className="w-full realtive flex items-center justify-center">
        <div
          className={`w-full flex relative border-b border-transparent overflow-hidden inputbox${
            isFocus.get() ? ' focused' : ''
          }`}
        >
          <input
            onFocus={() => isFocus.set(true)}
            onBlur={() => isFocus.set(false)}
            type="email"
            name="connect_with_us"
            className="form-input w-full flex border-transparent bg-transparent font-breadley-sans text-xl text-customColors-300 relative overflow-hidden rounded-none pl-0 pr-10 outline-none focus:outline-none focus:border-transparent focus:shadow-none focus:ring-transparent"
            placeholder="Email"
          />
        </div>
        <button
          type="submit"
          name="submit"
          className="absolute xs:right-5 lg:right-2 bg-transparent"
        >
          <SubmitArrow />
        </button>
      </div>
    </div>
  );
};

export default ContactWithUs;
