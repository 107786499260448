import React from 'react';
import { FBIcon } from '../../../static/svg/FBIcon';
import { InstaIcon } from '../../../static/svg/InstaIcon';
import { YoutubeIcon } from '../../../static/svg/YoutubeIcon';

const FollowUs = () => {
  return (
    <div className="w-full flex flex-row items-center justify-start xs:mt-6 lg:mt-0">
      <p className="text-customColors-300 text-base mr-10">Follow us at</p>
      <div className="flex-1 flex items-center justify-start space-x-14">
        <a href="https://www.instagram.com/roseandgold_india/" target="_blank" className="">
          <InstaIcon className="w-7 h-7" />
        </a>

        <a href="https://www.facebook.com/roseandgold.india" target="_blank" className="">
          <FBIcon className="w-7 h-7" />
        </a>

        <a href="https://www.youtube.com/vasuhealthcare" target="_blank" className="">
          <YoutubeIcon className="w-7 h-7" />
        </a>
      </div>
    </div>
  );
};

export default FollowUs;
