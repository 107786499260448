import React from 'react';
import { LeafVector } from '../../../static/svg/LeafVector';
import { mainNavigation } from '../constants';
import ContactWithUs from './ContactWithUs';
import FollowUs from './FollowUs';

const Footer = () => {
  return (
    <footer className="bg-customBackground-300 relative">
      <LeafVector className="absolute h-9/5 left-2 bottom-0" />
      <div className="relative w-full flex xs:flex-wrap lg:flex-row lg:flex-nowrap justify-center lg:container lg:mx-auto py-8">
        <div className="xs:w-full lg:w-3/5 flex xs:flex-col lg:flex-row">
          <div className="flex-1 flex xs:flex-row xs:items-end lg:flex-col justify-between lg:items-start xs:px-4 lg:px-0 lg:mr-10 lg:space-y-15">
            <a href="/" className="xs:w-1/2 lg:w-9/12 flex items-center justify-center">
              <img src="/images/R-&-G-Logo-v2.png" className="w-full h-full object-contain" />
            </a>
            <div className="lg:w-full flex items-end lg:items-center xs:justify-end lg:justify-start">
              <p className="text-base text-customColors-300 mr-2">By</p>
              <a
                href="https://www.vasuhealthcare.com/"
                target="_blank"
                className="xs:w-20 lg:w-24 flex items-center justify-center"
              >
                <img
                  src="/images/Vasu-Healthcare-Logo.png"
                  className="w-full h-full object-contain"
                  alt="vasu healthcare logo"
                />
              </a>
            </div>
          </div>
          <nav className="w-full lg:w-3/5 flex justify-between flex-nowrap lg:max-w-4xl p-4 xs:mt-5 lg:mt-0 mx-auto text-sm lg:px-8 lg:py-0 lg:space-x-5">
            <div className="xs:w-1/2 xs:flex-none lg:flex-1 flex flex-col space-y-5">
              {mainNavigation.map((navItem, index) => (
                <div key={index} className="w-full text-left">
                  <a
                    href={navItem.link}
                    className={`block lg:inline-flex lg:mt-0 text-customColors-300 relative overflow-hidden lg:after:bg-golden default-a${
                      index === 0 ? ' xs:mt-4' : ''
                    }`}
                  >
                    {navItem.name}
                  </a>
                </div>
              ))}
            </div>
            <div className="xs:w-1/2 xs:flex-none lg:flex-1 flex flex-col space-y-5">
              <div className="w-full text-left">
                <p className="text-customColors-300 xs:mt-4 lg:mt-0">Find Vasu at:</p>
              </div>
              <div className="w-full text-left">
                <a
                  target="_blank"
                  href="https://www.vasustore.com/"
                  className="block  lg:inline-flex lg:mt-0 text-customColors-300 relative overflow-hidden after:bg-golden default-a"
                >
                  Our Online Store
                </a>
              </div>
              <div className="w-full text-left">
                <a
                  target="_blank"
                  href="http://www.vasuhealthcare.com/"
                  className="block  lg:inline-flex lg:mt-0 text-customColors-300 relative overflow-hidden after:bg-golden default-a"
                >
                  Corporate Website
                </a>
              </div>
            </div>
          </nav>
        </div>
        <div className="xs:mt-6 lg:mt-0 xs:px-4 lg:pl-5 lg:pr-0 xs:w-full xs:flex-none lg:flex-1 flex flex-col justify-between">
          <ContactWithUs />
          <FollowUs />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
