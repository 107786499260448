import React from "react";

export const SubmitArrow = ({ className = "" }) => (
  <svg
    className={className}
    width="23"
    height="16"
    viewBox="0 0 23 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1 0.379869C13.86 0.422369 13.6675 0.59737 13.6 0.829869C13.5325 1.06487 13.6025 1.31487 13.78 1.47987L19.66 7.35987H0.760025C0.740025 7.35987 0.720025 7.35987 0.700025 7.35987C0.347525 7.37737 0.0725244 7.67737 0.0900245 8.02987C0.107525 8.38237 0.407525 8.65737 0.760025 8.63987H19.66L13.78 14.5199C13.59 14.6749 13.5025 14.9224 13.5575 15.1624C13.6125 15.3999 13.8 15.5874 14.0375 15.6424C14.2775 15.6974 14.525 15.6099 14.68 15.4199L21.66 8.45987L22.1 7.99987L21.66 7.53987L14.68 0.579869C14.5475 0.43737 14.355 0.36487 14.16 0.379869C14.14 0.379869 14.12 0.379869 14.1 0.379869Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5.12897"
        y1="11.1767"
        x2="21.9271"
        y2="3.88947"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
