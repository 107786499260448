import React, { useState } from 'react';
import { CartIcon } from '../../../static/svg/CartIcon';
import { mainNavigation } from '../constants';

const Header = () => {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <header className="bg-white">
      <div className="xs:p-4 lg:px-0 lg:py-8 flex flex-wrap items-center justify-between lg:container lg:mx-auto relative">
        <div className="xs:w-full lg:w-auto flex items-center justify-center lg:justify-start lg:space-x-10 static">
          <a href="/" className=" w-32">
            <img src="/images/R-&-G-Logo-v2.png" className="w-full object-contain" alt="R&G Logo" />
          </a>
          <button
            className="items-center flex text-black absolute left-5 lg:hidden outline-none focus:outline-none focus:border-none"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-5 h-5 text-customColors-300"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
          <nav
            className={`${
              isExpanded ? `xs:block` : `xs:hidden`
            } xs:w-screen xs:-mx-0 xs:shadow-lg xs:absolute xs:top-full xs:bg-white xs:z-99 lg:w-auto lg:flex lg:items-center lg:justify-between lg:flex-wrap lg:relative lg:bg-transparent lg:z-auto lg:shadow-none`}
          >
            <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto">
              <div className="text-base xs:mt-5 lg:mt-0 lg:flex-grow lg:space-x-10">
                {mainNavigation.map((navItem, index) => (
                  <a
                    key={index}
                    href={navItem.link}
                    className="xs:py-2 xs:px-4 lg:p-0 block lg:inline-block lg:mt-0 text-primary relative overflow-hidden lg:after:bg-primary default-a"
                  >
                    {navItem.name}
                  </a>
                ))}
              </div>
            </div>
          </nav>
        </div>
        <div className="xs:absolute xs:right-5 lg:right-unset lg:relative flex items-center justify-end xs:space-x-5 lg:space-x-0">
          <a
            href="https://www.vasustore.com/skin-care/r-g-skin-brightening-range"
            target="_blank"
            className="relative overflow-hidden flex cursor-pointer default-a lg:after:bg-golden text-customColors-300"
          >
            <CartIcon className="mr-2" />{' '}
            <span className="xs:hidden lg:inline-block">Buy at Vasu Store</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
