import React from "react";

export const CartIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 5.5H3.40189C2.88944 5.5 2.45993 5.88738 2.4072 6.3971L1.22819 17.7942C1.10615 18.9739 2.0316 20 3.21757 20H16.7824C17.9684 20 18.8938 18.9739 18.7718 17.7942L17.5928 6.3971C17.5401 5.88737 17.1106 5.5 16.5981 5.5H15.5M6 8V4.75C6 2.67893 7.67893 1 9.75 1V1C11.8211 1 13.5 2.67893 13.5 4.75V8"
      stroke="url(#paint0_linear)"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="5.1214"
        y1="14.4272"
        x2="20.2151"
        y2="10.1207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
