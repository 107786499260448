import React from "react";

export const FBIcon = ({ className = "" }) => (
  <svg
    className={className}
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 0.666992C6.648 0.666992 0.666626 6.64837 0.666626 14.0003C0.666626 21.3523 6.648 27.3337 14 27.3337C21.3519 27.3337 27.3333 21.3523 27.3333 14.0003C27.3333 6.64837 21.3519 0.666992 14 0.666992ZM14 2.66699C20.271 2.66699 25.3333 7.72925 25.3333 14.0003C25.3333 19.5875 21.3114 24.2046 16 25.1475V17.3337H18.4166C18.7526 17.3337 19.0368 17.0824 19.0781 16.749L19.3281 14.749C19.3521 14.5597 19.292 14.3696 19.1653 14.2256C19.0393 14.0823 18.858 14.0003 18.6666 14.0003H16V11.667C16 10.9317 16.598 10.3337 17.3333 10.3337H18.6666C19.0346 10.3337 19.3333 10.0357 19.3333 9.66699V7.41699C19.3333 7.07166 19.0699 6.7836 18.7265 6.75293C18.6872 6.7496 17.7466 6.66699 16.5559 6.66699C13.6179 6.66699 12 8.41112 12 11.5785V14.0003H9.33329C8.96529 14.0003 8.66663 14.2983 8.66663 14.667V16.667C8.66663 17.0357 8.96529 17.3337 9.33329 17.3337H12V25.1475C6.68851 24.2046 2.66663 19.5875 2.66663 14.0003C2.66663 7.72925 7.72888 2.66699 14 2.66699Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="6.7724"
        y1="19.5121"
        x2="28.9428"
        y2="12.8352"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C49E19" />
        <stop offset="1" stopColor="#F5C518" />
      </linearGradient>
    </defs>
  </svg>
);
